import { useAppSelector } from "../../../store/hooks";
import ganttChartStyles from "../../../styles/ganttChart/ganttChart.module.css";
const VerticalGanttChartCenteredCell = ({
  children,
  fullOpacity,
  bgColor
}: {
  children: React.ReactNode;
  fullOpacity?: boolean;
  bgColor?: string
}) => {
  const { verticalColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  return (
    <>
      {verticalColumnWidth && (
        <div
          className={ganttChartStyles.centeredCell}
          style={{
            backgroundColor: bgColor ? bgColor: "white",
            opacity: fullOpacity ? 1 : 0.5,
            width: verticalColumnWidth + "px",
            minWidth: verticalColumnWidth + "px",
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default VerticalGanttChartCenteredCell;
