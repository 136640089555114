import { Link } from "react-router-dom";
import buttonStyles from "../../../styles/UI/buttons.module.css";
import { ButtonVariantType } from "../../../types/buttons";

const StandardLinkButton = ({
  icon,
  children,
  variant,
  classes,
  disabled,
  to,
}: {
  icon?: React.ReactNode;
  children: React.ReactNode;
  variant: ButtonVariantType;
  to: string;
  classes?: string;
  disabled?: boolean;
}) => {
  return (
    <Link
      to={to}
      className={`${buttonStyles.standardButton} ${
        variant === "PRIMARY"
          ? buttonStyles.standardButtonPrimary
          : buttonStyles.standardButtonSecondary
      } ${disabled && buttonStyles.standardLinkButton__disabled} ${classes ? classes : null}`}
      style={{
        pointerEvents: disabled ? "none" : "auto",
      }}
    >
      {children}
      {icon}
    </Link>
  );
};

export default StandardLinkButton;
