import { TaskDependencyRenderingData } from "../../types/ganttChart";
import labels from "../../utils/labels";
import drawerStyles from "../../styles/drawer.module.css";
import DrawerBlock from "./DrawerBlock";
import TaskDependencyBox from "./TaskDependencyBox";

const TaskDependenciesList = ({
  taskDependencyRenderingData,
}: {
  taskDependencyRenderingData: TaskDependencyRenderingData;
}) => {
  const { dependsOn, isDependencyOf } = taskDependencyRenderingData;
  return (
    <DrawerBlock title={labels.dependencies}>
      <section className={drawerStyles.subTasksList}>
        {dependsOn.length > 0 && (
          <>
            <h3 className={drawerStyles.modalSubTitle}>
              {labels.dependsOnTitle}
            </h3>
            {dependsOn.map((task, index) => (
              <TaskDependencyBox task={task} key={index} />
            ))}
          </>
        )}

        {isDependencyOf.length > 0 && (
          <>
            <h3 className={drawerStyles.modalSubTitle}>
              {labels.isDependencyOfTitle}
            </h3>
            {isDependencyOf.map((task, index) => (
              <TaskDependencyBox task={task} key={index} />
            ))}
          </>
        )}
      </section>
    </DrawerBlock>
  );
};

export default TaskDependenciesList;
