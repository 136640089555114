import { TaskDependency } from "../../../types/ganttChart";
import { ReactComponent as DependencyIcon } from "../../../assets/icons/dependency.svg";
import chipStyles from "../../../styles/UI/chips.module.css";
import { useAppDispatch } from "../../../store/hooks";
import { setClickedTaskDependencies } from "../../../store/ganttChart";

const DependencyChip = ({
  dependencyArray,
  border,
  employeeName,
}: {
  dependencyArray: TaskDependency[];
  border?: boolean;
  employeeName: string;
}) => {
  const dispatch = useAppDispatch();
  return (
    <div
      className={chipStyles.chip}
      style={{
        backgroundColor: "var(--col-lavendar-dark)",
        border: border ? "1px solid white" : " none",
      }}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(setClickedTaskDependencies(dependencyArray));
      }}
    >
      <DependencyIcon />
    </div>
  );
};

export default DependencyChip;
