import { createSlice } from "@reduxjs/toolkit";
import {
  ActiveNexusFilter,
  EmployeeByTaskIdMap,
  GanttChartData,
  GanttChartDirection,
  GanttTabBarTab,
  PondooStatus,
  TaskDependency,
  TaskDependencyMap,
} from "../../types/ganttChart";
import {
  getGanttChartData,
  getPondooStatus,
  initiateGanttChartData,
  initiateOptimization,
} from "./action";
import labels from "../../utils/labels";
import createTaskDependencyMap, {
  createEmployeeByTaskIdMap,
} from "../../utils/taskDependencyUtils";

type GanttChartDirectionAction = {
  payload: GanttChartDirection;
};

type GanttChartTabAction = {
  payload: GanttTabBarTab;
};

type PendingDataFilters = { filter: string; date: string; requestId?: string };

export interface GanttChartSlice {
  selectedGanttChartTab: GanttTabBarTab;
  ganttChartDirection: GanttChartDirection;
  verticalColumnWidth: number;
  verticalSvgWidth: number;
  selectedActiveNexusFilter?: ActiveNexusFilter;
  translateXValueInPercent: number;
  ganttChartData?: GanttChartData;
  taskDependencyMap?: TaskDependencyMap;
  employeeByTaskIdMap?: EmployeeByTaskIdMap;
  pondooStatus?: PondooStatus;
  pondooStatusError?: string;
  showSpinner: boolean;
  error?: string;
  pendingData: PendingDataFilters[];
  clickedTaskDependencies?: TaskDependency[];
  scenarioIdToInitiatePondooSolution?: number;
}

const initialState: GanttChartSlice = {
  selectedGanttChartTab: "Nexus",
  ganttChartDirection: "VERTICAL",
  verticalColumnWidth: 0,
  verticalSvgWidth: 0,
  translateXValueInPercent: 0,
  showSpinner: false,
  pendingData: [],
};

const ganttChartSSlice = createSlice({
  name: "gantt-chart",
  initialState,
  reducers: {
    setSelectedGanttChartTab(state, action: GanttChartTabAction) {
      state.selectedGanttChartTab = action.payload;
    },
    setGanttChartDirection(state, action: GanttChartDirectionAction) {
      state.ganttChartDirection = action.payload;
    },
    setVerticalColumnWidth(state, action) {
      state.verticalColumnWidth = action.payload;
    },
    setVerticalSvgWidth(state, action) {
      state.verticalSvgWidth = action.payload;
    },
    clearGanttChartData(state) {
      delete state.ganttChartData;
    },
    setTranslateXValueInPercent(state, action) {
      state.translateXValueInPercent = action.payload;
    },
    setShowSpinner(state) {
      state.showSpinner = true;
    },
    setClickedTaskDependencies(state, action) {
      state.clickedTaskDependencies = action.payload;
    },
    deleteClickedTaskDependencies(state) {
      delete state.clickedTaskDependencies;
    },
    resetGanttChartErrorMessage(state) {
      delete state.error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initiateGanttChartData.pending, (state, action) => {
      delete state.ganttChartData;
      delete state.scenarioIdToInitiatePondooSolution;
      const filter = new URL(window.location.href).searchParams.get("filter");
      const date = new URL(window.location.href).searchParams.get("date");
      state.pendingData.push({
        filter: filter!,
        date: date!,
        requestId: action.meta.requestId,
      });
    });
    builder.addCase(initiateGanttChartData.fulfilled, (state, action) => {
      // Remove spinner when data is ready
      state.pendingData = state.pendingData.filter(
        (pendingDataObj) => pendingDataObj.requestId !== action.meta.requestId,
      );
      state.scenarioIdToInitiatePondooSolution = action.payload.idScenario;
    });
    builder.addCase(initiateGanttChartData.rejected, (state, action) => {
      // Remove spinner for requestID if request fails
      state.pendingData = state.pendingData.filter(
        (pendingDataObj) => pendingDataObj.requestId !== action.meta.requestId,
      );
    });
    builder.addCase(getGanttChartData.fulfilled, (state, action) => {
      state.showSpinner = false;
      state.ganttChartData = action.payload;
      state.taskDependencyMap = createTaskDependencyMap(action.payload);
      state.employeeByTaskIdMap = createEmployeeByTaskIdMap(action.payload);
    });
    builder.addCase(getGanttChartData.pending, (state) => {
      delete state.ganttChartData;
      delete state.pondooStatus;
      delete state.error;
      state.showSpinner = true;
    });
    builder.addCase(getGanttChartData.rejected, (state, action) => {
      state.showSpinner = false;
      // Todo: error handling
      const errorMessage = action.error.message;
      if (errorMessage?.includes("404")) {
        state.error =
          "Noget gik galt, da vi forsøgte at hente din plan. Prøv igen.";
      } else {
        state.error =
          "Noget gik galt, da vi forsøgte at hente din plan. Prøv igen.";
      }
    });
    builder.addCase(getPondooStatus.pending, (state) => {
      delete state.pondooStatus;
    });
    builder.addCase(getPondooStatus.fulfilled, (state, action) => {
      state.pondooStatus = action.payload;
    });
    builder.addCase(getPondooStatus.rejected, (state) => {
      state.pondooStatusError = labels.pondooStatusError;
      // Todo handle error.
    });
    builder.addCase(initiateOptimization.pending, (state) => {
      console.log("pending");
    });
    builder.addCase(initiateOptimization.rejected, (state) => {
      console.log("Error");
    });
    builder.addCase(initiateOptimization.fulfilled, (state, action) => {
      console.log(action.payload);
    });
  },
});

export const {
  setSelectedGanttChartTab,
  setGanttChartDirection,
  setVerticalColumnWidth,
  setVerticalSvgWidth,
  setTranslateXValueInPercent,
  clearGanttChartData,
  setShowSpinner,
  setClickedTaskDependencies,
  deleteClickedTaskDependencies,
  resetGanttChartErrorMessage,
} = ganttChartSSlice.actions;

export default ganttChartSSlice.reducer;
