import allowedPaths from "../utils/paths";

class headerService {
  static path = window.document.location.pathname;
  static showHeader = () => {
    if (
      this.path === allowedPaths.ganttChart ||
      this.path === allowedPaths.overview ||
      this.path === "/"
    )
      return true;
    else return false;
  };
}

export default headerService;
