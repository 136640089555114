import wrapperStyles from "../../styles/UI/wrappers.module.css";

export default function MainWhiteWrapper({
  children,
  classes,
  width,
}: {
  children: React.ReactNode | React.ReactNode[];
  classes?: string;
  width?: string;
}) {
  return (
    <div
      className={`${wrapperStyles.mainWhiteWrapper} ${
        classes ? classes : null
      }`}
      style={{ width: width }}
    >
      {children}
    </div>
  );
}
