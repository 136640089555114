import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { API_PATHS } from "../../service/api";

enum actionTypes {
  initiateGanttChartData = "initiateGanttChartData ",
  initiateOptimization = "initiateOptimization ",
  getGanttChartData = "getGanttChartData ",
  getPondooStatus = "getPondooStatus",
}

type GetGanttChartDataParams = {
  idActiveNexusFilter: number;
  idPlanningDate: number;
  pondooSolution: boolean
};

type InitieGanttChartDataParams = {
  filterId: number;
  date: string;
};

type GetPondooStatusParams = {
  idActiveNexusFilter: number;
  planningDate: string;
};

type InitiatePondooParams = {
  idScenario: number
};

export const initiateGanttChartData = createAsyncThunk(
  actionTypes.initiateGanttChartData,
  async (params: InitieGanttChartDataParams) => {
    const data = {
      idActiveNexusFilter: params.filterId,
      planningDate: params.date.split("T")[0],
    };
    const response = await api.post<any>(API_PATHS.initiateNexusPlan, data);
    return response.data;
  }
);

export const initiateOptimization = createAsyncThunk(
  actionTypes.initiateOptimization,
  async (param: number) => {
    const data = {
      idScenario: param
    };
    const response = await api.post<any>(API_PATHS.startOptimization, data);
    return response.data;
  }
);


export const getGanttChartData = createAsyncThunk(
  actionTypes.getGanttChartData,
  async (params: GetGanttChartDataParams) => {
    const response = await api.get<any>(
      `${API_PATHS.getNexusPlan}?idActiveNexusFilter=${params.idActiveNexusFilter}&idPlanningDate=${params.idPlanningDate}&pondooSolution=${params.pondooSolution}`
    );
    return response.data;
  }
);

export const getPondooStatus = createAsyncThunk(
  actionTypes.getPondooStatus,
  async (params: GetPondooStatusParams) => {
    const response = await api.get<any>(
      `${API_PATHS.retrievePondooStatus}?idActiveNexusFilter=${params.idActiveNexusFilter}&fullState=true&planningDate=${params.planningDate}`
    );
    return response.data;
  }
);
