import StandardButton from "../components/UI/buttons/StandardButton";
import ClipLoader from "react-spinners/ClipLoader";
import MainContentWrapper from "../components/UI/pageStructure/MainContentWrapper";
import GanttChartTabBar from "../components/ganttChart/filtering/GanttChartTabBar";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import HorizontalGanttWrapper from "../components/ganttChart/HorizontalGanttWrapper";
import VerticalGanttWrapper from "../components/ganttChart/VerticalGanttWrapper";
import IconButtonComponent from "../components/UI/buttons/IconButton";
import { ReactComponent as ZoomInIcon } from "../assets/icons/zoom-in.svg";
import { ReactComponent as ZoomOutIcon } from "../assets/icons/zoom-out.svg";
import ColouredRoundWrapper from "../components/UI/ColouredRoundWrapper";
import {
  zoomIn,
  zoomInOnVerticalView,
  zoomOut,
  zoomOutOnVerticalView,
} from "../store/timeline";
import ganttCHartValues from "../utils/ganttChartValues";
import { initiateGanttChartData, initiateOptimization } from "../store/ganttChart/action";
import Drawer from "../components/UI/Drawer";
import DrawerContent from "../components/drawer/DrawerContent";
import {useSearchParams } from "react-router-dom";
import PondooPlanStausBar from "../components/blackRoundInfoComponents/PondooPlanStausBar";
import { useEffect } from "react";
export default function GanttChartPage() {
  const dispatch = useAppDispatch();
  const { isDrawerOpen } = useAppSelector((state) => state.modalSlice);
  const { ganttChartDirection, ganttChartData, showSpinner, pendingData, error, scenarioIdToInitiatePondooSolution } =
    useAppSelector((state) => state.ganttChartSlice);
  const { timelineRange } = useAppSelector((state) => state.timelineSlice);
  const { selectedNexusFilterDate,  selectedActiveNexusFilter } =
    useAppSelector((state) => state.nexusFiltersSlice);

  const [searchParams] = useSearchParams();

  // Get a search param
  const filter = searchParams.get("filter");
  const date = searchParams.get("date");

  const isDataBeingRetrieved = pendingData.some(
    (dataObj: any) => dataObj.filter === filter && dataObj.date === date
  );

  useEffect(()=>{if(scenarioIdToInitiatePondooSolution){
    dispatch(initiateOptimization(scenarioIdToInitiatePondooSolution))
  }}, [scenarioIdToInitiatePondooSolution])

  const handleZoomIn = () => {
    if (ganttChartDirection === "HORIZONTAL") {
      dispatch(zoomIn(ganttCHartValues.zoomInHours));
    } else {
      const pixelsFromTop = document.getElementById(
        "vertical-ganttChart-svg-wrapper"
      )?.scrollTop;
      dispatch(zoomInOnVerticalView(pixelsFromTop!));
    }
  };
  const handleZoomOut = () => {
    if (ganttChartDirection === "HORIZONTAL") {
      dispatch(zoomOut(ganttCHartValues.zoomInHours));
    } else {
      const pixelsFromTop = document.getElementById(
        "vertical-ganttChart-svg-wrapper"
      )?.scrollTop;
      dispatch(zoomOutOnVerticalView(pixelsFromTop!));
    }
  };

  return (
    <>
      <MainContentWrapper classes="flex flex-col">
        {/* Todo: change design, when design is completed */}
        {!ganttChartData && !showSpinner && !isDataBeingRetrieved &&  (
          <div className="flex flex-col gap-[10px] items-center justify-center h-full">
        {error ?<p style={{color: "var(--col-dirt-rose-dark)"}}>{error}</p> :    <p>
              <b>Vi mangler en plan for i dag.</b> <br />
              Tryk på knappen herunder for at hente den.
            </p>}
            <StandardButton
              classes="w-[300px]"
              onClick={() =>
                dispatch(
                  initiateGanttChartData({
                    filterId: selectedActiveNexusFilter!.id,
                    date: selectedNexusFilterDate!,
                  })
                )
              }
              variant="SECONDARY"
              disable={!selectedActiveNexusFilter}
            >
              Hent vagtplan
            </StandardButton>
          </div>
        )}
        {(showSpinner || isDataBeingRetrieved) && (
          <div
            className="flex items-center justify-center"
            style={{ width: "100%", height: "100%" }}
          >
            <ClipLoader
              loading={true}
              color="var(--col-black)"
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={0.5}
            />
          </div>
        )}
        {timelineRange &&
          ganttChartData &&
          !showSpinner &&
          !isDataBeingRetrieved && (
            <article className="flex justify-end items-center p-3 min-h-[100px]">
              <div className="flex gap-[10px]">
                <ColouredRoundWrapper bgColor="var(--col-grey-1)" padding>
                  <IconButtonComponent
                    disable={timelineRange!.hoursInView <= 2}
                    id="zoom-in"
                    icon={<ZoomInIcon />}
                    onClick={handleZoomIn}
                  />
                  {timelineRange.hoursInView}
                  <IconButtonComponent
                    disable={timelineRange!.hoursInView === 24}
                    id="zoom-out"
                    icon={<ZoomOutIcon />}
                    onClick={handleZoomOut}
                  />
                </ColouredRoundWrapper>
                <GanttChartTabBar />
              </div>
            </article>
          )}
        {ganttChartData && ganttChartDirection === "HORIZONTAL" && (
          <HorizontalGanttWrapper ganttChartData={ganttChartData} />
        )}
        {ganttChartData &&
          !showSpinner &&
          !isDataBeingRetrieved &&
          ganttChartDirection === "VERTICAL" && <VerticalGanttWrapper />}
      </MainContentWrapper>

      {isDrawerOpen && (
        <Drawer>
          <DrawerContent />
        </Drawer>
      )}
      <PondooPlanStausBar />
    </>
  );
}
