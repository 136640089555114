import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import useWindowResize from "./useWindowResize";
import ganttCHartValues from "../utils/ganttChartValues";

const useGanttChartPlacement = () => {
  // Wrapper
  const [
    ganttChartHorizontalSvgContainer,
    setGanttChartHorizontalSvgContainer,
  ] = useState<any>();
  const [svgWidth, setSvgWidth] = useState<number>();
  const { windowWidth } = useWindowResize();

  const [horizontalSvgHeight, setHortizontalSvgHeight] = useState<number>();
  const { ganttChartData } = useAppSelector((state) => state.ganttChartSlice);
  // Navigation
  const selectedGanttChartTab = useAppSelector(
    (state) => state.ganttChartSlice.selectedGanttChartTab
  );
  const [showExpandedTask, setShowExpandedTask] = useState<boolean>(false);

  // Row values
  const [individualRowHeight, setIndividualRowHeight] = useState<number>(
    ganttCHartValues.rowHeight
  );
  const [unplannedRowHeight, setUnplannedRowHeight] = useState<number>(
    ganttCHartValues.individualUnplannedRowHeight * 2
  );
  const [unplannedRowHeightWrapper, setUnplannedRowHeightWrapper] =
    useState<number>(ganttCHartValues.individualUnplannedRowHeight * 2 + 20);

  useEffect(() => {
    setGanttChartHorizontalSvgContainer(
      document.getElementById("ganttChartHorizontalSvgContainer")
    );
    calculateSvgHeight();
  }, [ganttChartData]);

  const calculateSvgHeight = () => {
    const offSet = ganttCHartValues.headerRowHeight + unplannedRowHeightWrapper;
    if (selectedGanttChartTab === "Both") {
      setHortizontalSvgHeight(
        ganttChartData!.employees.length * individualRowHeight * 2 + offSet
      );
    } else {
      setHortizontalSvgHeight(
        ganttChartData!.employees.length * individualRowHeight + offSet
      );
    }
  };

  // Everytime unplannedRowHeight changes, the wrapper should change as well.
  // Add the top and bottom margin.
  useEffect(() => {
    if (selectedGanttChartTab === "Both")
      setUnplannedRowHeightWrapper(unplannedRowHeight * 2 + 20);
    else setUnplannedRowHeightWrapper(unplannedRowHeight + 20);
  }, [unplannedRowHeight]);

  // Handle fiiltering on type of Gantt view: Pondoo, Nexus or both.
  useEffect(() => {
    if (selectedGanttChartTab === "Both") {
      setIndividualRowHeight(ganttCHartValues.rowHeight);
      setUnplannedRowHeight(ganttCHartValues.individualUnplannedRowHeight);
      setShowExpandedTask(false);
    } else {
      setIndividualRowHeight(ganttCHartValues.expandedRowHeight);
      setUnplannedRowHeight(ganttCHartValues.expandedUnplannedRowHeight);
      setShowExpandedTask(true);
    }
  }, [selectedGanttChartTab]);

  // ReCalculate svg Height everytime tab has been clicked and values updated.
  useEffect(() => {
    calculateSvgHeight();
  }, [individualRowHeight, unplannedRowHeight]);

  // Handle window rezising.
  useEffect(() => {
    if (ganttChartHorizontalSvgContainer) {
      const svgWidth = ganttChartHorizontalSvgContainer.clientWidth;
      setSvgWidth(svgWidth);
    }
  }, [windowWidth]);

  return {
    ganttChartHorizontalSvgContainer,
    horizontalSvgHeight,
    individualRowHeight,
    unplannedRowHeight,
    unplannedRowHeightWrapper,
    showExpandedTask,
    svgWidth,
    windowWidth,
  };
};

export default useGanttChartPlacement;
