import { TransportationType } from "../../../types/ganttChart";
import { ReactComponent as CarIcon } from "../../../assets/icons/car.svg";
import { ReactComponent as BikeIcon } from "../../../assets/icons/bike.svg";
import { ReactComponent as WalkingIcon } from "../../../assets/icons/walking.svg";
import { useEffect, useRef } from "react";
import iconUtil from "../../../utils/iconUtil";

const VerticalGanttChartTransportationIcon = ({
  type,
  color,
  size
}: {
  type: TransportationType;
  color?: string;
  size?: number
}) => {
  const parent = useRef(null);
  const getIcon = () => {
    switch (type) {
      case "BIKE":
        return <BikeIcon width={size? size :16} height={size? size :16} />;
      case "WALK":
        return <WalkingIcon width={size? size :16} height={size? size :16} />;
      case "CAR":
        return <CarIcon width={size? size :16} height={size? size :16} />;
      case "ELECTRIC_BIKE":
        return <BikeIcon width={size? size :16} height={size? size :16} />;
    }
  };

  useEffect(() => {
    if (parent.current && color) {
      iconUtil.updateIconColor(parent.current, color);
    }
  }, [parent]);

  return <div ref={parent}>{getIcon()}</div>;
};

export default VerticalGanttChartTransportationIcon;
