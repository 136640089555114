import { useEffect, useState } from "react";
import useGanttChartPlacement from "../../../../hooks/useGanttChartPlacement";
import ganttCHartValues from "../../../../utils/ganttChartValues";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setCoordinates, setIsDragging } from "../../../../store/dragAndDrop";
import { openDrawer } from "../../../../store/drawer";
import ganttChartService from "../../../../service/ganttChartService";
import { WorkTask } from "../../../../types/ganttChart";
import taskStyles from "../../../../styles/ganttChart/tasks.module.css";

const TaskComponent = ({ y, task, employeeName }: { y: number; task: WorkTask, employeeName?: string }) => {
  const dispatch = useAppDispatch();
  const { pixelPerMinute, timelineArrayOfTimeString } = useAppSelector(
    (state) => state.timelineSlice
  );
  const { xPosition, yPosition, isDragging } = useAppSelector(
    (state) => state.dragAndDropSlice
  );
  const { showExpandedTask, ganttChartHorizontalSvgContainer, svgWidth } =
    useGanttChartPlacement();

  const [placementOnXAxis, setPlacementOnXAxis] = useState<number>(0);
  const [taskWidth, setTaskWidth] = useState<number>();
  const [taskBeingDragged, setTaskBeingDragged] = useState<number>();

  const calculatePlacementOnXAxis = () => {
    const firstVisibleHourAsDate = timelineArrayOfTimeString
      ? new Date(timelineArrayOfTimeString[0])
      : new Date();
    // subtract one hour, since firstVisibleHour represents the first number shown on the timeline, which is one hour later than the beginning of the ganttChart.
    firstVisibleHourAsDate.setHours(firstVisibleHourAsDate.getHours() - 1);
    const differenceBetweenFirstHourAndTask =
      // @ts-ignore
      new Date(task.start) - firstVisibleHourAsDate;
    const differenceInminutes = differenceBetweenFirstHourAndTask / 60000;
    setPlacementOnXAxis(differenceInminutes * pixelPerMinute!);
  };

  const calculateTaskWidth = () => {
    const timespanInMinutes = ganttChartService.timeDifferenceInMinutes(
      task.start,
      task.end
    );
    setTaskWidth(timespanInMinutes * pixelPerMinute!);
  };

  useEffect(() => {
    calculatePlacementOnXAxis();
    calculateTaskWidth();
  }, [pixelPerMinute, svgWidth, timelineArrayOfTimeString]);

  // Calculate how much the text needs to be offset, to land in the middle of the task <rect>
  const taskTextAdjustmentOnYAxis = showExpandedTask
    ? (ganttCHartValues.expandedIndividualTaskHeight -
        ganttCHartValues.taskTextLineHeight) /
      2
    : ganttCHartValues.individualTaskHeight / 2;

  // Mouseevents to handle drag and drop
  const handleMouseDown = () => {
    // Start tracking mouse movement
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: any) => {
    const boundingClientReact =
      ganttChartHorizontalSvgContainer.getBoundingClientRect();
    document.body.style.userSelect = "none"; // Disable text selection
    dispatch(setIsDragging());
    setTaskBeingDragged(task.idTaskPlan);
    dispatch(
      setCoordinates({
        x: e.clientX - boundingClientReact.left,
        y: e.clientY - boundingClientReact.top,
      })
    );
  };
  const handleMouseUp = () => {
    // Stop tracking mouse movement
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);

    // If not dragging, treat it as a click
    if (!isDragging) {
      dispatch(openDrawer({task: task, employeeName: employeeName}))
    }
  };

  return (
    <>
      <rect
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onClick={() => {
          dispatch(openDrawer({task: task, employeeName: employeeName}));
        }}
        // Question for Michael: should it be idTask or idTaskplan?
        x={
          isDragging && taskBeingDragged === task.idTaskPlan
            ? xPosition
            : placementOnXAxis
        }
        y={
          isDragging && taskBeingDragged === task.idTaskPlan
            ? yPosition
            : y + 10
        }
        width={taskWidth}
        height={
          showExpandedTask
            ? ganttCHartValues.expandedIndividualTaskHeight
            : ganttCHartValues.individualTaskHeight
        }
        fill="var(--col-after-eight)"
        rx={4}
      >
        {task.client.description}
      </rect>
      <title>
        Borger: {task.client.description} Opgaver:{" "}
        {task.subTasks.map((task) => task + ", ")}
      </title>
      <foreignObject
        onMouseDown={handleMouseDown}
        x={
          isDragging && taskBeingDragged === task.idTaskPlan
            ? xPosition
            : placementOnXAxis
        }
        y={
          isDragging && taskBeingDragged === task.idTaskPlan
            ? yPosition
            : // unplannedRowHeight here, and further up plus in the row,
              // needs to be added based on boolean that says something like "unplanned". So that Y in the future will be calculated in a const inside the component,
              //  based on index, y and unplanned boolean.
              y + taskTextAdjustmentOnYAxis
        }
        width={taskWidth}
        height={
          showExpandedTask
            ? ganttCHartValues.expandedIndividualTaskHeight
            : ganttCHartValues.individualTaskHeight
        }
      >
        <div className="flex flex-col">
          <p className={taskStyles.verticalTaskText}>
            {task.client.description}
          </p>
          {/* Todo: rewrite */}
          {showExpandedTask && (
            <p className={taskStyles.verticalTaskText}>
              {task.subTasks[0].description}
            </p>
          )}
        </div>
      </foreignObject>
    </>
  );
};

export default TaskComponent;
