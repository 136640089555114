import { useEffect } from "react";
import MainWhiteWrapper from "../components/UI/MainWhiteWrapper";
import labels from "../utils/labels";

export default function KPIPage() {
  useEffect(() => {}, []);
  return (
    <article className="flex flex-col w-full gap-[var(--spacing-sm)]">
      <MainWhiteWrapper>{labels.citizenService}</MainWhiteWrapper>
      <div className="flex gap-[var(--spacing-sm)]">
        <MainWhiteWrapper width="50%">{labels.time}</MainWhiteWrapper>
        <MainWhiteWrapper width="50%">{labels.expenses}</MainWhiteWrapper>
      </div>
    </article>
  );
}
