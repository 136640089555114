import statusStyles from "../../styles/UI/statusIcon.module.css";
import { PondooJobStatus } from "../../types/ganttChart";

// Todo: move out of component and pass color
const CirclularStatusIcon = ({ status }: { status: PondooJobStatus }) => {
  const getStatusColorClass = () => {
    switch (status) {
      case (status = "COMPLETED"):
        return statusStyles.circleGreen;
      case (status = "FAILED"):
        return statusStyles.circleRed;
      case (status = "MISSING"):
        return statusStyles.circleRed;
      case (status = "PENDING"):
        return statusStyles.circlePurple;
      case (status = "RUNNING"):
        return statusStyles.circlePurple;
      case (status = "OUTDATED"):
        return statusStyles.circleYellow;
    }
  };

  return (
    <div className={`${statusStyles.circle} ${getStatusColorClass()}`}></div>
  );
};

export default CirclularStatusIcon;
