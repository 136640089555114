import pageStyles from "../../../styles/page.module.css";
import Header from "../../header/Header";
import PageNavigation from "../../navigation/PageNavigation";

export default function PageWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <article className={pageStyles.pageWrapper}>
      <Header />
      <section className={pageStyles.pageBody}>
        <PageNavigation />
        {children}
      </section>
    </article>
  );
}
