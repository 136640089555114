import axios from "axios";
import {
  ApiResult,
  AuthResponse,
  ApiFailure,
  ApiSuccess,
  TokenPair,
} from "../types/apiTypes";
import { jwtDecode } from "jwt-decode";

export const baseURL = window._env_.REACT_APP_API_URL;

export const API_PATHS = {
  getCalendarDates: "/retrieve-planning-dates",
  initiateNexusPlan: "/start-nexus-data-extraction",
  getNexusFilters: "/retrieve-nexus-filters",
  getActiveNexusFilters: "/retrieve-active-nexus-filters",
  getNexusPlan: "/gantt-chart-data",
  getCitizenKPIList: "/todo:addhere",
  retrieveKPIs: "/todo:add",
  retrievePondooStatus: "/jobs/state",
  startOptimization: "/start-optimization"
} as const;

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(function (config) {
  config.headers.Authorization = "Basic cWFtdXNlcjpxYW1wYXNzd29yZA==";
  config.headers["X-Responsible-User-Id"] = 1; // TODO: Temporary fix
  return config;
});

// api.interceptors.request.use(
//   async (config) => {
//     const token = localStorage.getItem("access_token");
//     if (token) {
//       try {
//         const decodedToken = jwtDecode(token);

//         const expiryDate = new Date(decodedToken.exp! * 1000);
//         const now = new Date();

//         // Check if we are expired OR have less than two minutes to live
//         if (isExpired(expiryDate, now)) {
//           const refreshResponse =
//             await authService.performTokenRefreshAndReturnTokens();

//           localStorage.setItem(
//             "access_token",
//             refreshResponse?.accessToken as string
//           );
//           localStorage.setItem(
//             "refresh_token",
//             refreshResponse?.refreshToken as string
//           );

//           config.headers.Authorization = `Bearer ${refreshResponse?.accessToken}`;
//         } else {
//           config.headers.Authorization = `Bearer ${token}`;
//         }
//       } catch (error) {
//         // Could not decode the token or something
//         console.log("Error", error);
//         localStorage.clear();
//         authService.redirectToLogin();
//       }
//     }

//     return Promise.resolve(config);
//   },
//   async (error) => Promise.resolve(error)
// );

export const postData = async <T>(
  url: string,
  data: any,
  headers?: Record<string, string>
): Promise<ApiResult<T>> => {
  try {
    const response = await api.post(url, data, { headers });
    return { success: true, data: response.data } as ApiResult<T>;
  } catch (err: any) {
    // Do your errorhandling here
    console.error(err);
    return { success: false } as ApiFailure;
  }
};

export class authService {
  static tokenURL = window._env_.REACT_APP_TOKEN_URL;
  static loginURL = window._env_.REACT_APP_LOGIN_URI;
  static clientId = window._env_.REACT_APP_CLIENT_ID;
  static clientSecret = window._env_.REACT_APP_CLIENT_SECRET;

  static headers: Record<string, string> = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  static redirectToLogin = () => (window.location.href = this.loginURL);

  static performTokenRefreshAndReturnTokens = async (): Promise<
    TokenPair | undefined
  > => {
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken || refreshToken === "") {
      console.error(
        "Attempted to perform a token refresh without an actual refresh token; forcing login instead"
      );
      this.redirectToLogin();
    }

    const params = new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: refreshToken as string,
      client_id: this.clientId,
      client_secret: this.clientSecret,
    });

    // Call token refresh endpoint using fetch, to avoid triggering an infinite loop in the Axios intercept handler
    const response = await fetch(this.tokenURL, {
      method: "POST",
      headers: this.headers,
      body: params,
    });

    if (response.status === 200) {
      const data = await response.json();
      return this.setAuthResponseInLocalStorageAndReturnToken({
        success: true,
        data: data,
      });
    } else {
      console.error("Could not retrieve an authorization token");
      this.redirectToLogin();
    }
  };

  private static setAuthResponseInLocalStorageAndReturnToken = (
    authResponse: ApiSuccess<AuthResponse>
  ): TokenPair => {
    const { access_token, expires_in, refresh_token, refresh_expires_in } =
      authResponse.data;

    localStorage.setItem("access_token", access_token);
    localStorage.setItem("access_token_expires_in", expires_in);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("refresh_expires_in", refresh_expires_in);

    return { accessToken: access_token, refreshToken: refresh_token };
  };
}

const isExpired = (expiry: Date, now: Date): boolean => {
  const expiryTime = expiry.getTime();
  const nowTime = now.getTime();

  if (expiryTime - nowTime <= 0) return true;

  return (expiryTime - nowTime) / (60 * 1000) <= 1; // If we have less than one minute until expiration, renew the token
};

export default api;
