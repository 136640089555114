import { ReactComponent as WarningIcon } from "../../assets/icons/warning.svg";
import violationStyles from "../../styles/UI/violation.module.css";
import { Violations } from "../../types/ganttChart";
import labels from "../../utils/labels";

const ViolationBox = ({ violations }: { violations: Violations }) => {
  return (
    <div className={violationStyles.violationBox}>
      <div className="flex gap-[--spacing-sm] items-center">
        <WarningIcon width={14} height={14} />
        <p className={violationStyles.violationBoxTitle}>Regelbrud</p>
      </div>
      <ul className={violationStyles.violationBoxList}>
        {violations.map((violation, index) => (
          <li key={index} className={violationStyles.violationBoxText}>
            {(() => {
              switch (violation) {
                case "SKILL":
                  return labels.violationSkill;
                case "DEPENDENCY":
                  return labels.violationDependency;
                case "DEPENDENCY_SAME_EMPLOYEE":
                  return labels.violationDependencySameEmployee;
                case "OVERDUE":
                  return labels.violationOverdue;
                case "OVERTIME":
                  return labels.violationOvertime;
                case "TRAVEL_TOTAL_DISTANCE":
                  return labels.violationTravelTotalDistance;
                case "TRAVEL_TRIP_DISTANCE":
                  return labels.violationTravelTripDistance;
                case "TRAVEL_FIRST_LAST_TRIP_DISTANCE":
                  return labels.violationTravelFirstLastTripDistance;
                default:
                  return violation;
              }
            })()}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ViolationBox;
