import { useDispatch } from "react-redux";
import { GanttTabBarTab } from "../../../types/ganttChart";
import TabButton from "../../UI/buttons/TabButton";
import { setSelectedGanttChartTab } from "../../../store/ganttChart";
import { useAppSelector } from "../../../store/hooks";
import ganttCHartValues from "../../../utils/ganttChartValues";
import { useEffect, useState } from "react";
import ColouredRoundWrapper from "../../UI/ColouredRoundWrapper";

const GanttChartTabBar = () => {
  const dispatch = useDispatch();
  const { selectedGanttChartTab, ganttChartDirection } = useAppSelector(
    (state) => state.ganttChartSlice
  );

  const tabRow: GanttTabBarTab[]=[
    "Pondoo",
    "Nexus",
  ];


  return (
    <ColouredRoundWrapper bgColor="var(--col-grey-1)">
      {tabRow.map((tab) => (
        <TabButton
          key={tab}
          selected={ganttCHartValues.getTabLabel(selectedGanttChartTab)}
          onClick={() => dispatch(setSelectedGanttChartTab(tab))}
          value={ganttCHartValues.getTabLabel(tab)}
        ></TabButton>
      ))}
    </ColouredRoundWrapper>
  );
};

export default GanttChartTabBar;
