import { useEffect, useState } from "react";
import { Employee } from "../../../types/ganttChart";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import { useAppSelector } from "../../../store/hooks";

const VerticalGanttChartSeparationLines = ({
  employees,
  verticalColumnWidth,
  pixelsPerHour,
}: {
  employees: Employee[];
  verticalColumnWidth: number;
  pixelsPerHour: number;
}) => {
  const { timelineRange } = useAppSelector((state) => state.timelineSlice);
  const getXPosition = (index: number) => {
    return verticalColumnWidth >=
      ganttChartVerticalValues.verticalViewMinColumnWidth
      ? ganttChartVerticalValues.verticalViewHeaderColumn +
          verticalColumnWidth +
          index * verticalColumnWidth
      : ganttChartVerticalValues.verticalViewHeaderColumn +
          ganttChartVerticalValues.verticalViewMinColumnWidth +
          index * ganttChartVerticalValues.verticalViewMinColumnWidth;
  };

  return (
    <>
      {timelineRange &&
        employees.map((_, index) => (
          <line
            key={index}
            id="column-seperation-line"
            x1={getXPosition(index)}
            x2={getXPosition(index)}
            y1={0}
            y2={timelineRange.fullDayHours * pixelsPerHour}
            stroke="var(--col-grey)"
          />
        ))}
    </>
  );
};

export default VerticalGanttChartSeparationLines;
