import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import ganttCHartValues from "../../../../utils/ganttChartValues";
import CombinedTaskRowBackgroundColor from "../rightsideRows/CombinedTaskRowBackgroundColor";
import useGanttChartPlacement from "../../../../hooks/useGanttChartPlacement";
import { dropTask, setCoordinates } from "../../../../store/dragAndDrop";
import TimeLineHeader from "./TimeLineHeader";
import UnplannedRow from "../rightsideRows/UnplannedRow";
import AllTasks from "../tasks/AllTasks";

const Timeline = () => {
  const dispatch = useAppDispatch();
  const isDragging = useAppSelector(
    (state) => state.dragAndDropSlice.isDragging
  );
  const { ganttChartHorizontalSvgContainer, svgWidth } =
    useGanttChartPlacement();

  const { timelineRange, pixelsPerHour } = useAppSelector(
    (state) => state.timelineSlice
  );

  const handleMouseMove = (e: any) => {
    const boundingClientReact =
      ganttChartHorizontalSvgContainer.getBoundingClientRect();
    if (isDragging) {
      document.body.style.userSelect = "none";
      dispatch(
        setCoordinates({
          x: e.clientX - boundingClientReact.left,
          y: e.clientY - boundingClientReact.top,
        })
      );
    }
  };

  const handleMouseUp = () => {
    document.body.style.userSelect = "";
    dispatch(dropTask());
  };

  return (
    <div
      id="ganttChartHorizontalSvgContainer"
      className="absolute top-0 w-[calc(100%-300px)]"
      style={{
        left: ganttCHartValues.nameColumnWidthString,
        borderLeft: "1px solid var(--col-primary)",
      }}
    >
      {timelineRange && (
        <svg
          width="100%"
          height={
            pixelsPerHour ? timelineRange.fullDayHours * pixelsPerHour : 100
          }
          id="ganttchart-svg"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <g>
            {svgWidth && (
              <>
                <UnplannedRow rowWidth={svgWidth} />
                <CombinedTaskRowBackgroundColor rowWidth={svgWidth} />
                <AllTasks />
              </>
            )}
            {/* Needs to appear below everything else, in order to ensure that lines are drawn above everything else. */}
            <TimeLineHeader />
          </g>
        </svg>
      )}
    </div>
  );
};

export default Timeline;
