import Accordion from "../UI/Accordion";
import headerStyles from "../../styles/header.module.css";
import HeaderCalendar from "./HeaderCalendar";
import HeaderKPIList from "./HeaderKPIList";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setActiveNexusFilter } from "../../store/nexusFilters";
import {
  getActiveNexusFilters,
  getPlanningDates,
} from "../../store/nexusFilters/action";
import { useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/Pondoo_clean_logo.svg";
import StandardButton from "../UI/buttons/StandardButton";
import {
  getGanttChartData,
  getPondooStatus,
  initiateGanttChartData,
} from "../../store/ganttChart/action";
import {  useSearchParams } from "react-router-dom";
import { clearGanttChartData, resetGanttChartErrorMessage, setShowSpinner } from "../../store/ganttChart";
import labels from "../../utils/labels";
import StandardLinkButton from "../UI/buttons/StandardLinkButton";
import allowedPaths from "../../utils/paths";
import headerService from "../../service/header";

const Header = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getActiveNexusFilters());
  }, []);

  const {
    activeNexusFilters,
    selectedActiveNexusFilter,
    selectedNexusFilterDate,
    planningDates,
  } = useAppSelector((state) => state.nexusFiltersSlice);

  const [searchParams, setSearchParams] = useSearchParams();
  // Get a search param
  const filter = searchParams.get("filter");
  const date = searchParams.get("date");

  const { pendingData, selectedGanttChartTab } = useAppSelector((state) => state.ganttChartSlice);

  const handleFilterChange = (filterName: string, value?: string) => {
    dispatch(resetGanttChartErrorMessage())
    setSearchParams((prevParams) => {
      if (value) {
        prevParams.set(filterName, value);
      } else {
        prevParams.delete(filterName);
      }
      return prevParams;
    });
  };

  useEffect(() => {
    const isDataBeingRetrieved = pendingData.some(
      (dataObj) => dataObj.filter === filter && dataObj.date === date
    );
    if (isDataBeingRetrieved) {
      dispatch(setShowSpinner());
    }
  }, [date]);

  useEffect(() => {
    const selectedPlan =
      selectedNexusFilterDate &&
      planningDates?.find(
        (date) =>
          new Date(date.planningDate).getTime() ===
          new Date(selectedNexusFilterDate).getTime()
      );

    const aPlanIsReadyToBeDisplayed =
      selectedActiveNexusFilter &&
      selectedPlan;

    const isDataBeingRetrieved = pendingData.some(
      (dataObj) => dataObj.filter === filter && dataObj.date === date
    );

    if (isDataBeingRetrieved) {
      dispatch(setShowSpinner());
      return;
    }
    // To do: add a check, so that ths only runs, if the pendingData change was related to the currently selected filters.
    if (aPlanIsReadyToBeDisplayed) {
      // TOdo: when landing on a page
      // Todo: come back to, to check if able to retrieve pondoo plan.

      dispatch(
        getGanttChartData({
          idActiveNexusFilter: selectedActiveNexusFilter.id,
          idPlanningDate: selectedPlan.id,
          pondooSolution: selectedGanttChartTab === "Pondoo" ? true: false
        })
      );
      dispatch(
        getPondooStatus({
          idActiveNexusFilter: selectedActiveNexusFilter.id,
          planningDate: selectedPlan.planningDate,
        })
      );
    } else {
      dispatch(clearGanttChartData());
    }
  }, [selectedNexusFilterDate, planningDates, pendingData, selectedGanttChartTab]);


  return (
    <div className={headerStyles.header}>
      <div className="flex items-center gap-[var(--spacing-lg)]">
        <Logo width={40} height={40} />
        {headerService.showHeader() ? (
          <>
            <div className={headerStyles.headerFilterWrapper}>
              {activeNexusFilters && (
                <Accordion
                  variant="SECONDARY"
                  onChange={(option) => {
                    dispatch(getPlanningDates(option.id));
                    dispatch(setActiveNexusFilter(option));
                    handleFilterChange("filter", option.id.toString());
                  }}
                  options={activeNexusFilters}
                  labelKey="filter"
                  defaultText={labels.filter}
                  displayOption={0}
                />
              )}
              <HeaderCalendar
                setDateSearchParam={(date) => {
                  handleFilterChange("date", date);
                }}
              />
              <HeaderKPIList />
            </div>
            <StandardButton
              onClick={() => {
                dispatch(
                  initiateGanttChartData({
                    filterId: selectedActiveNexusFilter!.id,
                    date: selectedNexusFilterDate!,
                  })
                );
              }}
              variant="SECONDARY"
              disable={!selectedActiveNexusFilter}
            >
              Hent vagtplan
            </StandardButton>
          </>
        ) : (
          <div className={headerStyles.headerFilterWrapper}>
            <p>{labels.citizen}</p>
          </div>
        )}
      </div>
      {headerService.showHeader() && (
        <StandardLinkButton variant="PRIMARY" to={allowedPaths.citizens}>
          {labels.citizens}
        </StandardLinkButton>
      )}
    </div>
  );
};

export default Header;
