import useGanttChartPlacement from "../../../../hooks/useGanttChartPlacement";
import timestampService from "../../../../service/timestampService";
import ganttCHartValues from "../../../../utils/ganttChartValues";
import ganttChartStyles from "../../../../styles/ganttChart/ganttChart.module.css";
import { useEffect } from "react";
import ganttChartService from "../../../../service/ganttChartService";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setPixelsPerMinute } from "../../../../store/timeline";

const TimeLineHeader = () => {
  const dispatch = useAppDispatch();
  const { svgWidth, horizontalSvgHeight } = useGanttChartPlacement();
  const {
    timelineArrayOfTimeString,
    timelineRange,
    pixelPerMinute,
    pixelsPerHour,
  } = useAppSelector((state) => state.timelineSlice);

  useEffect(() => {
    if (timelineRange) {
      dispatch(
        setPixelsPerMinute(
          ganttChartService.getPixelsPerMinute(
            svgWidth!,
            timelineRange.hoursInView
          )
        )
      );
    }
  }, [svgWidth, timelineArrayOfTimeString, timelineRange]);

  return (
    <>
      <foreignObject
        x="0"
        y="0"
        width={svgWidth}
        height={ganttCHartValues.rowHeight}
      >
        <div
          id="gantt-chart-timeline"
          className={ganttChartStyles.timelineHeader}
          style={{
            width: svgWidth,
            height: ganttCHartValues.rowHeight,
            paddingLeft: (pixelPerMinute! * 60) / 2,
          }}
        >
          {pixelPerMinute &&
            timelineArrayOfTimeString &&
            timelineArrayOfTimeString.map((timePoint, index) => (
              <p
                className="flex items-center justify-center"
                style={{ width: pixelsPerHour }}
              >
                <span className="w-[30px] flex justify-end">
                  {timestampService.returnTwoDigitTimeString(
                    new Date(timePoint).getHours()
                  )}
                </span>
                <span className="w-[8px] flex items-center justify-center">
                  :
                </span>
                <span className="w-[30px]">
                  {timestampService.returnTwoDigitTimeString(
                    new Date(timePoint).getMinutes()
                  )}
                </span>
              </p>
            ))}
        </div>
      </foreignObject>
      {pixelsPerHour &&
        timelineRange &&
        timelineArrayOfTimeString &&
        timelineArrayOfTimeString.map((_, index) => (
          <line
            key={index + 1}
            x1={(index + 1) * pixelsPerHour}
            y1="30"
            x2={(index + 1) * pixelsPerHour}
            y2={timelineRange.fullDayHours * pixelsPerHour}
            stroke={ganttCHartValues.mochaColor}
            strokeWidth="1"
          />
        ))}
    </>
  );
};

export default TimeLineHeader;
