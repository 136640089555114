import { useAppSelector } from "../../../../store/hooks";
import TaskComponent from "./IndividualTaskComponent";
import useGanttChartPlacement from "../../../../hooks/useGanttChartPlacement";
import ganttChartService from "../../../../service/ganttChartService";

const AllTasks = () => {
  const { selectedGanttChartTab, ganttChartData } = useAppSelector(
    (state) => state.ganttChartSlice
  );

  const { individualRowHeight, unplannedRowHeightWrapper } =
    useGanttChartPlacement();

  return (
    <>
      {/* Todo: make sure all task Types are covered */}
      {/* Todo: fix selectBar functionality */}
      {/* Todo: is this still needed? */}
      {/* {(selectedGanttChartTab === "Both" ||
        selectedGanttChartTab === "Pondoo") &&
        ganttChartData?.pondooSpecific &&
        ganttChartData?.pondooSpecific.unplannedTasks.workTasks.map((task) => (
          <TaskComponent
            y={ganttChartService.findTaskPlacementOnYAxisForPlannedTask(
              0,
              individualRowHeight,
              selectedGanttChartTab,
              selectedGanttChartTab === "Pondoo" ? 10 : 5
            )}
            task={task}
          />
        ))} */}
      {/* Nexus row */}
      {/* todo: is this needed: selectedGanttChartTab === "Nexus" && */}
      { ganttChartData?.generalGanttChartInfo.unplannedTasks.workTasks.map((task) => (
          <TaskComponent
            y={ganttChartService.findTaskPlacementOnYAxisForPlannedTask(
              selectedGanttChartTab === "Nexus" ? 0 : 0.5,
              individualRowHeight,
              selectedGanttChartTab,
              selectedGanttChartTab === "Nexus" ? 10 : -5
            )}
            task={task}
          />
        ))}
      {ganttChartData!.employees.map((employee, index) => (
        <>
          {/* Pondoo row */}
          {/* Todo: is this needed */}
          {/* {selectedGanttChartTab === "Pondoo" &&
            employee.pondooTasks &&
            employee.pondooTasks.workTasks.map((task) => (
              <TaskComponent
                y={ganttChartService.findTaskPlacementOnYAxisForPlannedTask(
                  index,
                  individualRowHeight,
                  selectedGanttChartTab,
                  unplannedRowHeightWrapper
                )}
                task={task}
                employeeName={employee.fullName}
              />
            ))} */}
          {/* Nexus row */}
          {/* todo: is this needed selectedGanttChartTab === "Nexus" && */}
          { employee.tasks.workTasks.map((task) => (
              <TaskComponent
                y={ganttChartService.findTaskPlacementOnYAxisForPlannedTask(
                  selectedGanttChartTab === "Nexus" ? index : index + 0.5,
                  individualRowHeight,
                  selectedGanttChartTab,
                  unplannedRowHeightWrapper
                )}
                task={task}
                employeeName={employee.fullName}
              />
            ))}
        </>
      ))}
    </>
  );
};

export default AllTasks;
