import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { NotFound } from "./pages/NotFound";
import { useEffect, useState } from "react";

import Layout from "./pages/Layout";
import GanttChartPage from "./pages/GanttChartPage";
import { authService } from "./service/api";
import KPIPage from "./pages/KPIPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <GanttChartPage /> },
      { path: "/overview", element: <KPIPage /> },
      { path: "/ganttChart", element: <GanttChartPage /> },
      { path: "*", element: <NotFound /> },
    ],
  },
]);

const App = () => {
  const [parametersHandled, setParametersHandled] = useState<boolean>(false);

  // Tanja
  useEffect(() => {
    if (parametersHandled) return;

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const refreshToken = urlParams.get("refreshToken");

    if (token && refreshToken) {
      localStorage.setItem("access_token", token);
      localStorage.setItem("refresh_token", refreshToken);
      setParametersHandled(true);
    } else {
      authService.redirectToLogin();
    }
  }, [parametersHandled]);

  return <RouterProvider router={router} />;
};
export default App;
