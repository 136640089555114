class timelineService {
  static getFirstVisibleHour = (startDate: number): number => {
    const fullHours = new Date(startDate).getHours();
    let firstVisibleHour;
    if (fullHours - 1 >= 0) {
      firstVisibleHour = new Date(startDate).setHours(fullHours - 1);
    } else firstVisibleHour = new Date(startDate).setHours(0);
    return firstVisibleHour;
  };

  static getHoursInView = (startDate: number, endDate: number) => {
    const diffInTime =
      new Date(endDate).getTime() - new Date(startDate).getTime();
    const diffInHours = diffInTime / (1000 * 60 * 60);
    return Math.round(diffInHours);
  };

  static returnDurationInMinutes = (duration: string) => {
    let durationString = duration.split("T")[1];
    durationString = durationString.split("M")[0];
    return durationString;
  };
}

export default timelineService;
