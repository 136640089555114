import ganttChartStyles from "../../styles/ganttChart/ganttChart.module.css";
import colors from "../../utils/colors";
const KpiWrapper = ({
  value,
  showColoredChip,
  children
}: {
  value: number;
  showColoredChip?: boolean;
  children?: React.ReactNode
}) => {
  return (
    <div className={`${ganttChartStyles.kpiChip}`}>
      {showColoredChip && (
        <div
          className={ganttChartStyles.kpiBullet}
          style={{
            backgroundColor: colors.getBackgroundColor(value),
          }}
        ></div>
      )}
      {children}
      {value ? value : 0}
    </div>
  );
};

export default KpiWrapper;
