import useGanttChartPlacement from "../../../../hooks/useGanttChartPlacement";
import ganttChartService from "../../../../service/ganttChartService";
import { useAppSelector } from "../../../../store/hooks";

const UnplannedRow = ({ rowWidth }: { rowWidth: number }) => {
  const selectedGanttChartTab = useAppSelector(
    (state) => state.ganttChartSlice.selectedGanttChartTab
  );

  const { individualRowHeight, unplannedRowHeightWrapper, unplannedRowHeight } =
    useGanttChartPlacement();
  return (
    <>
      {/* Definition of striped pattern */}
      <defs>
        <pattern
          id="pattern-stripe"
          width="4"
          height="4"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(45)"
        >
          <rect
            width="2"
            height="4"
            transform="translate(0,0)"
            fill="white"
          ></rect>
        </pattern>
        <mask id="mask-stripe">
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="url(#pattern-stripe)"
          />
        </mask>
      </defs>
      {/* White background row */}
      <rect
        x={0}
        y={ganttChartService.getPlacementOnYaxisForWhiteRow(
          0,
          selectedGanttChartTab,
          individualRowHeight
        )}
        width={rowWidth}
        height={unplannedRowHeightWrapper}
        fill="white"
      />
      {/* Individual row - when eother Pondoo or Nexus */}
      <rect
        style={{ mask: "url(#mask-stripe)", fill: "var(--col-primary)" }}
        x={0}
        y={
          ganttChartService.getPlacementOnYaxisForWhiteRow(
            0,
            selectedGanttChartTab,
            individualRowHeight
          ) + 10
        }
        width={rowWidth}
        height={unplannedRowHeight}
      />
      {/* Combined row i.e. when "Both" */}
      {selectedGanttChartTab === "Both" && (
        <rect
          style={{ mask: "url(#mask-stripe)", fill: "var(--col-primary)" }}
          x={0}
          y={ganttChartService.getPlacementOnYAxisForColoredRow(0)}
          width={rowWidth}
          height={unplannedRowHeight}
        />
      )}
    </>
  );
};

export default UnplannedRow;
